import React from 'react';
import fabia from './fabia.jpg';

  
const Aktuality = () => {
  return (
   
   <div>
    
    <header class="Apptitulek">Aktuality</header>
      <br></br>
      <h2>Změny pro řidiče 2024</h2>
       <p> Stručný přehled změn, které čekají řidiče v roce 2024. Pokud není uvedeno jinak, daná změna platí od 1. ledna 2024.</p>
       <p></p>
      <a 
      className="App-link" 
      href="https://ridici2024.my.canva.site/cz"
      target="_blank"
      rel="noopener noreferrer"
      > 
      Odkaz na změny pro řidiče 2024
      </a>      
      <hr></hr>
      <h2>Zkuste si nanečisto nový test!</h2>
        <a 
      className="App-link" 
      href="https://etesty2.mdcr.cz/Test/TestExam/16"
      target="_blank"
      rel="noopener noreferrer"
      > 
      Odkaz na zkušební test
      </a>      
      <hr></hr>
      <h2>Výcvikový vůz</h2>
         <p>Škoda Fabia combi - benzín</p>
         <img className="fotky" src={fabia} alt="foto_auto"/>
         <hr></hr>
      </div>
  );
};
  
export default Aktuality;