import React from 'react';
  
const Dokumenty = () => {


    const onButtonClick = () => {
        const pdfUrl = "zadost.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "zadost.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const onButtonClick2 = () => {
      const pdfUrl = "lekarsky_posudek.jpg";
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "lekarsky_posudek.jpg"; // specify the filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  };

  return (

    <div className='App'>
      <h1>Ke stažení</h1>
      <p>Žádost se musí tisknout oboustranně. Lékařský posudek samostatně.</p>
      <p></p>
      <button onClick={onButtonClick}>
                    Stáhnout žádost PDF
      </button>
      <p></p>
      <button onClick={onButtonClick2}>
                    Stáhnout lékařský posudek
      </button>
    
      
      </div>
  );
};
  
export default Dokumenty;