import { FaBars } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';


  
export const Nav = styled.nav`
background: linear-gradient(to bottom, black,white);
height: 2vw;
  font-family: Cabin;
  height: calc(3vw);
  
`;

 
export const NavLink = styled(Link)`
color: white;
text-align:center;
padding-right: calc(1.5vw);
padding-left: calc(1.5vw);
padding-top: calc( (2.4vw - 1.2vw) * 0.5) ;
font-family: Cabin;
font-size: calc(1.2vw);
font-weight: bold;
float: left;
display: block;
min-height:2.4vw;
align:right;
  &.active {
    color: black;
  }
`

export const Linklogo = styled(Link)`
align: left;
display: block;
max-width: calc(8vw);
float:left;
padding: calc(0.1vw) calc(0.1vw) calc(0.1vw) calc(0.1vw);
`;
  
export const Bars = styled(FaBars)`
  color: #234e9c;
  font-family: Cabin;
  `;
  
export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  font-family: Cabin;
  margin-right: -24px;
  /* Second Nav */
  /* margin-right: 24px; */
  /* Third Nav */
  /* width: 100vw;
  white-space: nowrap; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
  
export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  font-family: Cabin;
  margin-right: 24px;

`;
  
export const NavBtnLink = styled(Link)`
text-align:center;
padding-right: calc(1.5vw);
padding-left: calc(1.5vw);
padding-top: calc( (2.4vw - 1.2vw) * 0.5) ;
font-family: Cabin;
font-size: calc(1.2vw);
font-weight: bold;
color: white;
outline: none;
border: none;
cursor: pointer;
min-height: 2.4vw;
text-decoration: none;
/* Second Nav */
margin-left: 1vw;
&:hover {
  background: #0094DE;
  color: white;
  }
`;