import React from 'react';
import fotka from './foto-svata.jpg';
  
const Kontakt = () => {
  return (

<div className='App'>
    <div>
      <h1>Autoškola Ing. Svatoslav Velechovský mladší</h1>
      <p>Žitětín 21</p>
      <p>Jičíněves-Jičín 50601</p>
      <p></p>

<h1>Provozovna:</h1>
<p>Štrauchova 975 </p>
<p>Jičín 50601</p>
<p>IČ 76050530</p>
<p>tel.: +420 732 565 933</p>
<p>email: velechov@seznam.cz</p>
 
<img className="fotka_face" src={fotka} alt="foto"/>
</div>
</div>
  );
};
  
export default Kontakt;