import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Intro from './pages/intro';
import Kontakt from './pages/kontakt';
import Navbar from './components/Navbar';
import Cenik from './pages/cenik';
import Dokumenty from './pages/dokumenty';
import './App.css';


function App() {
  
    return ( 
    <div className='App'>
    <Router>
    <Navbar />
        <Routes>
        <Route path="/" element={<Intro />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/cenik" element={<Cenik />} />
        <Route path="/dokumenty" element={<Dokumenty />} />
        </Routes>
        </Router>
        </div>
    );
   
}

export default App;
