import React from 'react';
import logo from './logo.png';
import {
  Nav,
  NavLink,
  NavBtn,
  NavBtnLink, Linklogo
} from './NavbarElements';

 
const Navbar = () => {

   return (
    <>
    <div className="App">
    <header className="App-header">
    <div>
    <Linklogo to="/">
    <img className="logo" src={logo} alt="logo"/>
    </Linklogo>
    </div>
        <p></p>
        &nbsp;&nbsp;Autoškola
          <p></p>
          &nbsp;&nbsp;Velechovský ml. <p align="right" className='mobil' >tel.: +420 732 565 933&nbsp;&nbsp;&nbsp;</p>
          
        </header>
        
        </div>
        <Nav>
         
        <NavBtn>
        <NavBtnLink to='/kontakt'>Kontakt</NavBtnLink>
        <NavBtnLink to='/cenik'>Ceník</NavBtnLink>
        <NavBtnLink to='/dokumenty'>Dokumenty</NavBtnLink>
        <NavLink to='https://www.asociaceautoskol.cz/' activeStyle>
            Asociace autoškol ČR
          </NavLink>
        </NavBtn>
      
      </Nav>
      
      </>
  );

};
  
export default Navbar;