import React from 'react';
  
const Cenik = () => {
  return (

    <div className='App'>
      <h1>Ceník</h1>
      <p>Ceník platný od 1.1.2024</p>
      <p>Osobní automobil - sk. B: 18 000,-Kč</p>
      </div>
  );
};
  
export default Cenik;