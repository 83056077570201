import React from 'react';
import Aktuality from './aktuality';

  
const Intro = () => {
  return (
    
    <div className='App'>

      <div class="column left">
      <header class="Apptitulek">Vítejte</header>

      <br></br>
    
      <h2>Nabízím individuální výuku a výcvik pro získání řidičského oprávnění skupiny B. Výuka, výcvik a závěrečná zkouška probíhají v Jičíně.</h2>
      <h2>Odvoz zajištěn.</h2>
      <h2>Ing. Svatoslav Velechovský</h2>
      <h2>Tel.: 732 565 933</h2>
      <h2>Email: velechov@seznam.cz</h2>
<hr></hr>

    <hr></hr>
    </div>
    
    <div class="column right"><Aktuality></Aktuality></div>
    
    <br></br>
    
   <div class="footer">
  <p>Web created by Galda, 2024</p>
    </div>
    </div>

    );
};
  
export default Intro;